import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _abaeef16 = () => interopDefault(import('../_templates/pages/blog/Blog.vue' /* webpackChunkName: "" */))
const _bf3b1716 = () => interopDefault(import('../_templates/pages/checkout/Checkout.vue' /* webpackChunkName: "" */))
const _72fdef94 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Order.vue' /* webpackChunkName: "" */))
const _86b6111a = () => interopDefault(import('../_templates/pages/checkout/Checkout/Account.vue' /* webpackChunkName: "" */))
const _6b5a617f = () => interopDefault(import('../_templates/pages/checkout/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _ca8a781a = () => interopDefault(import('../pages/customer/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _993c8f8c = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _4578a95a = () => interopDefault(import('../modules/part-finder/pages/FindMyTractor.vue' /* webpackChunkName: "" */))
const _4ba6db94 = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _54e5ef0c = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _19c19ef4 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _fe681dd8 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _fa878b8e = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _4a6f393a = () => interopDefault(import('../_templates/pages/faq/Faq.vue' /* webpackChunkName: "" */))
const _f647b956 = () => interopDefault(import('../_templates/pages/wishlist/Wishlist.vue' /* webpackChunkName: "" */))
const _2ca467b5 = () => interopDefault(import('../modules/part-finder/pages/Finders.vue' /* webpackChunkName: "" */))
const _60bed116 = () => interopDefault(import('../_templates/pages/search/Search.vue' /* webpackChunkName: "" */))
const _b76795d6 = () => interopDefault(import('../_templates/pages/payment-svea/Checkout.vue' /* webpackChunkName: "" */))
const _1a350cf6 = () => interopDefault(import('../pages/catalog/Category.vue' /* webpackChunkName: "pages/catalog/Category" */))
const _757db8f5 = () => interopDefault(import('../_templates/pages/customer/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _3fbb0f6a = () => interopDefault(import('../_templates/pages/payment-svea/CheckoutConfirmation.vue' /* webpackChunkName: "" */))
const _4d65dac4 = () => interopDefault(import('../pages/customer/MyAccount/useSidebarLinkGroups.ts' /* webpackChunkName: "pages/customer/MyAccount/useSidebarLinkGroups" */))
const _3031feb5 = () => interopDefault(import('../_templates/pages/blog/BlogPost.vue' /* webpackChunkName: "" */))
const _dad4ec58 = () => interopDefault(import('../modules/part-finder/pages/Brands.vue' /* webpackChunkName: "" */))
const _4f41df24 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollection.vue' /* webpackChunkName: "" */))
const _7f62d6d7 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollectionItem.vue' /* webpackChunkName: "" */))
const _83bb611c = () => interopDefault(import('../modules/part-finder/pages/Models.vue' /* webpackChunkName: "" */))
const _e21b8ff0 = () => interopDefault(import('../modules/part-finder/pages/Parts.vue' /* webpackChunkName: "" */))
const _2a79dfe2 = () => interopDefault(import('../_templates/pages/theme/Home.vue' /* webpackChunkName: "" */))
const _3af825bf = () => interopDefault(import('../_templates/pages/theme/Page.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktuelt",
    component: _abaeef16,
    name: "blog___ad_nb_no"
  }, {
    path: "/checkout",
    component: _bf3b1716,
    name: "checkout___ad_nb_no",
    children: [{
      path: "bestilling",
      component: _72fdef94,
      name: "checkout-order___ad_nb_no"
    }, {
      path: "konto",
      component: _86b6111a,
      name: "checkout-account___ad_nb_no"
    }, {
      path: "takk",
      component: _6b5a617f,
      name: "checkout-thank-you___ad_nb_no"
    }]
  }, {
    path: "/customer",
    component: _ca8a781a,
    meta: {"titleLabel":"Account"},
    name: "customer___ad_nb_no",
    children: [{
      path: "addresses-details",
      component: _993c8f8c,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___ad_nb_no"
    }, {
      path: "find-my-tractor",
      component: _4578a95a,
      meta: {"titleLabel":"Find my tractor"},
      name: "customer-find-my-tractor___ad_nb_no"
    }, {
      path: "my-profile",
      component: _4ba6db94,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile___ad_nb_no"
    }, {
      path: "order-history",
      component: _54e5ef0c,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___ad_nb_no"
    }, {
      path: "addresses-details/create",
      component: _19c19ef4,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___ad_nb_no"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _fe681dd8,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___ad_nb_no"
    }, {
      path: "order-history/:orderId",
      component: _fa878b8e,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___ad_nb_no"
    }]
  }, {
    path: "/faq.html",
    component: _4a6f393a,
    name: "faq___ad_nb_no"
  }, {
    path: "/onskeliste",
    component: _f647b956,
    name: "wishlist___ad_nb_no"
  }, {
    path: "/parts",
    component: _2ca467b5,
    name: "part-finder___ad_nb_no"
  }, {
    path: "/search",
    component: _60bed116,
    name: "search___ad_nb_no"
  }, {
    path: "/sveacheckout",
    component: _b76795d6,
    name: "sveaCheckout___ad_nb_no"
  }, {
    path: "/catalog/Category",
    component: _1a350cf6,
    name: "catalog-Category___ad_nb_no"
  }, {
    path: "/customer/reset-password",
    component: _757db8f5,
    alias: "/customer/account/createPassword",
    name: "reset-password___ad_nb_no"
  }, {
    path: "/sveacheckout/confirmation",
    component: _3fbb0f6a,
    name: "sveaCheckoutConfirmation___ad_nb_no"
  }, {
    path: "/customer/MyAccount/MyAccount",
    component: _ca8a781a,
    name: "customer-MyAccount-MyAccount___ad_nb_no"
  }, {
    path: "/customer/MyAccount/useSidebarLinkGroups",
    component: _4d65dac4,
    name: "customer-MyAccount-useSidebarLinkGroups___ad_nb_no"
  }, {
    path: "/aktuelt/:slug+",
    component: _3031feb5,
    name: "blogPost___ad_nb_no"
  }, {
    path: "/parts/:finder",
    component: _dad4ec58,
    name: "part-finder-finders___ad_nb_no"
  }, {
    path: "/collection/:slug([^/.]+).html",
    component: _4f41df24,
    name: "attributeCollection___ad_nb_no"
  }, {
    path: "/collection/:collection_slug/:slug+.html",
    component: _7f62d6d7,
    name: "attributeCollectionItem___ad_nb_no"
  }, {
    path: "/parts/:finder/:brand",
    component: _83bb611c,
    name: "part-finder-models___ad_nb_no"
  }, {
    path: "/parts/:finder/:brand/:model",
    component: _e21b8ff0,
    name: "part-finder-parts___ad_nb_no"
  }, {
    path: "/",
    component: _2a79dfe2,
    name: "home___ad_nb_no"
  }, {
    path: "/:slug+",
    component: _3af825bf,
    name: "page___ad_nb_no"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
